import React, { useState } from 'react'
import { PlusOutlined, EditOutlined } from '@ant-design/icons'
import AddCategory from './AddCategory';
import Edit from './Edit';
const Categories = (props) => {
    const { categories, setCategories, setCategory, category } = props;
    const [isModalOpen, setisModalOpen] = useState(false);
    const [isEditModalOpen, setisEditModalOpen] = useState(false);
  return (
    <ul className='flex md:flex-col gap-4 text-lg'>
        <li onClick={() => setCategory("Tümü")} className={category === "Tümü" ? "!bg-pink-700 category-item" : "category-item"}>
            <span>Tümü</span>
        </li>
        {
            categories.map((categorie) => {
                return (
                    <li onClick={() => setCategory(categorie.title)} key={categorie._id} className={categorie.title === category ? "!bg-pink-700 category-item" : "category-item"}>
                        <span>{categorie.title}</span>
                    </li>
                )
            })
        }
        <li onClick={() => {setisModalOpen(true)}} className='category-item !bg-purple-800 hover:opacity-90'>
            <span>
                <PlusOutlined className='md:text-2xl'/>
            </span>
        </li>
        <li onClick={() => {setisEditModalOpen(true)}} className='category-item !bg-orange-800 hover:opacity-90'>
            <span>
                <EditOutlined className='md:text-2xl'/>
            </span>
        </li>
        <AddCategory categories={categories} setCategories={setCategories} isModalOpen={isModalOpen} setisModalOpen={setisModalOpen}/>
        <Edit categories={categories} setCategories={setCategories} isEditModalOpen={isEditModalOpen} setisEditModalOpen={setisEditModalOpen}/>
    </ul>
  )
}

export default Categories