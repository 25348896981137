import React from "react";

const StatsCard = (props) => {
    const { title, value } = props;
    let img;
    if(title === "Toplam Müşteri") {img = "img/user.png"}
    else if(title === "Toplam Kazanç") { img = "img/money.png" }
    else if(title === "Toplam Satış") { img = "img/sale.png" } 
    else if(title === "Toplam Ürün") { img = "img/product.png" }
  return (
    <div className="stats-card bg-gray-800 text-white rounded-lg p-8">
      <div className="flex gap-x-4">
        <div className="bg-white rounded-full p-3">
          <img className="w-12 h-12 object-cover" src={img} alt="" />
        </div>
        <div className="flex flex-col justify-center">
          <p className="mb-2 text-lg font-medium text-gray-400">
            {title}
          </p>
          <p className="text-xl font-semibold text-gray-200">{value}</p>
        </div>
      </div>
    </div>
  );
};

export default StatsCard;
