import React, { useEffect, useState } from 'react'
import Categories from '../components/categories/Categories'
import Products from '../components/products/Products'
import CartTotals from '../components/cart/CartTotals'
import { Spin } from 'antd'
const Home = () => {
  const [categories, setCategories] = useState();
  const [category, setCategory] = useState("Tümü");
  console.log(category);
  useEffect(() => {
    const getCategories = async () => {
      try {
        const res = await fetch(process.env.REACT_APP_SERVER_URL + "/api/categories/get-all");
        const json = await res.json();
        setCategories(json);
      } catch (error) {
        console.log(error);
      }
    }

    getCategories();
  }, [])
  

  return (
      <>
        {
          categories ? (
            <div className='px-6 flex h-screen md:flex-row flex-col justify-between gap-10 md:pb-0 pb-16'>
              <div className="categories overflow-auto max-h-[calc(100vh_-_112px)] md:pb-10">
                  <Categories category={category} categories={categories} setCategory={setCategory} setCategories={setCategories}/>
              </div>
              <div className="products flex-[8] md:pb-10 overflow-y-auto max-h-[calc(100vh_-_112px)] min-h-[500px]">
                <Products category={category} categories={categories}/>
              </div>
              <div className="cart-wrapper min-w-[300px] md:-mr-[24px] md:-mt-[24px] border">
                <CartTotals />
              </div>
            </div>
          ) :  <Spin size="large" className="absolute top-1/3 left-1/2" />
        }
    </>
  )
}

export default Home