import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { PlusOutlined, EditOutlined } from "@ant-design/icons";
import Add from "./Add";
import ProductItem from "./ProductItem";
import { useSelector } from "react-redux";
import { Spin } from "antd";

const Products = ({ categories, category }) => {
  const search = useSelector((state) => state.search);
  const [products, setProducts] = useState();
  const [isModalOpen, setisModalOpen] = useState(false);

  const filterTitle = (product) => {
    if (
      product.title.toLowerCase().includes(search.searchWords.toLowerCase())
    ) {
      return <ProductItem key={product._id} product={product} />;
    } else {
      return null;
    }
  };

  useEffect(() => {
    const getProducts = async () => {
      try {
        const res = await fetch(process.env.REACT_APP_SERVER_URL + "/api/products/get-all");
        const json = await res.json();
        setProducts(json);
      } catch (error) {
        console.log(error);
      }
    };

    getProducts();
  }, []);

  return (
    <>
      {products ? (
        <>
          <div className="products-wrapper grid grid-cols-card gap-4">
            {products.map((product) => {
              if (category === "Tümü") {
                return filterTitle(product);
              } else if (product.category === category) {
                return filterTitle(product);
              } else {
                return null;
              }
            })}
            <div
              onClick={() => setisModalOpen(true)}
              className="min-w-[150px] min-h-[150px] product-item border hover:shadow-md cursor-pointer transition-all select-none !bg-purple-800  hover:!bg-purple-700 flex items-center justify-center text-white"
            >
              <PlusOutlined className="md:text-2xl" />
            </div>
            <NavLink
              to="/products"
              className="min-w-[150px] min-h-[150px] product-item border hover:shadow-md cursor-pointer transition-all select-none !bg-orange-800 hover:!bg-orange-700 flex items-center justify-center text-white"
            >
              <EditOutlined className="md:text-2xl" />
            </NavLink>
          </div>
          <Add
            categories={categories}
            isModalOpen={isModalOpen}
            setisModalOpen={setisModalOpen}
            products={products}
            setProducts={setProducts}
          />
        </>
      ) : (
        <Spin size="large" className="absolute top-1/3 left-1/2" />
      )}
    </>
  );
};

export default Products;
