import { createSlice } from "@reduxjs/toolkit";

const searchSlice = createSlice({
    name: "search",
    initialState: {
        searchWords: "",
    },
    reducers: {
        searchProduct: (state, action) => {
            state.searchWords = action.payload.words;
        },
    }
})



export const { searchProduct } = searchSlice.actions
export default searchSlice.reducer