import React, { useEffect, useState } from "react";
import Header from "../components/header/Header";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import Home from "../pages/Home";
import Cart from "../pages/Cart";
import BillPage from "../pages/BillPage";
import ClientsPage from "../pages/ClientsPage";
import StatsPage from "../pages/StatsPage";
import Register from "../pages/auth/Register";
import Login from "../pages/auth/Login";
import ProductsPage from "../pages/ProductsPage";
import { useSelector } from "react-redux";
const AppRouter = () => {
  const [user, setUser] = useState({})
  const cart = useSelector((state) => state.cart);
  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cart));
    const getuser = localStorage.getItem("posUser");
    setUser(getuser)
  },[cart, user])
  
  const { pathname } = useLocation();
  console.log(pathname);
  return (
    <>
      <Header setUser={setUser} pathname={pathname}/>
      <Routes>
        <Route path="/" element={user ? <Home /> : <Navigate to="/login"/>}/>
        <Route path="/cart" element={user ? <Cart /> : <Navigate to="/login"/>} />
        <Route path="/bills" element={user ? <BillPage /> : <Navigate to="/login"/>} />
        <Route path="/clients" element={user ? <ClientsPage /> : <Navigate to="/login"/>} />
        <Route path="/stats" element={user ? <StatsPage /> : <Navigate to="/login"/>}/>
        <Route path="/products" element={user ? <ProductsPage /> : <Navigate to="/login"/>} />
        <Route path="/register" element={!user ? <Register /> : <Navigate to="/"/> } />
        <Route path="/login" element={!user ? <Login setUser={setUser}/> : <Navigate to="/"/> } />
      </Routes>
    </>
  );
};

export default AppRouter;
