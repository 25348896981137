import React, { useState, useEffect} from 'react'
import Edit from '../components/products/Edit'
const ProductsPage = () => {

  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const getProducts = async () => {
      try {
        const res = await fetch(process.env.REACT_APP_SERVER_URL + "/api/products/get-all");
        const json = await res.json();
        setProducts(json);
      } catch (error) {
        console.log(error);
      }
    };

    const getCategories = async () => {
      try {
        const res = await fetch(process.env.REACT_APP_SERVER_URL +"/api/categories/get-all");
        const json = await res.json();
        setCategories(json);
      } catch (error) {
        console.log(error);
      }
    }

    getCategories();

    getProducts();
  }, []);
  return (
    <div>
        <div className='px-6'>
            <h1 className='text-4xl font-bold mb-4 text-center'>Ürünler</h1>
            <Edit products={products} setProducts={setProducts} categories={categories}/>
        </div>
    </div>
  )
}

export default ProductsPage