import React, { useEffect, useState } from "react";
import { Spin, Table } from "antd";
const ClientsPage = () => {
  const [customers, setCustomers] = useState();
  useEffect(() => {
    const getBills = async () => {
      const res = await fetch(process.env.REACT_APP_SERVER_URL + "/api/bills/get-all");
      const json = await res.json();
      if (res.ok) {
        setCustomers(json);
      }
    };

    getBills();
  }, []);

  const columns = [
    {
      title: "Müşteri Adı",
      dataIndex: "customerName",
      key: "customerName",
    },
    {
      title: "Telefon Numarası",
      dataIndex: "customerPhone",
      key: "customerPhone",
    },
    {
      title: "İşlem Tarihi",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => {
        const date = new Date(text).toLocaleDateString("tr-TR");
        return <span>{date}</span>;
      },
    },
  ];

  return (
    <>
      {customers ? (
        <div className="px-6">
          <h1 className="text-4xl font-bold text-center mb-4">Müşterilerim</h1>
          <Table
            rowKey="_id"
            dataSource={customers}
            columns={columns}
            bordered
            scroll={{
              x: 1000,
              y: 300,
            }}
            pagination={false}
          />
        </div>
      ) : (
        <Spin size="large" className="absolute top-1/3 left-1/2" />
      )}
    </>
  );
};

export default ClientsPage;
