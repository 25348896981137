import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Form, Input, Button, Carousel, message } from "antd";
import AuthCarousel from "../../components/auth/AuthCarousel";
const Register = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const onFinish = async (values) => {
    setLoading(true);
    try {
      const res = await fetch(process.env.REACT_APP_SERVER_URL +"/api/auth/register", {
        body: JSON.stringify(values),
        method: "POST",
        headers: {"Content-type" : "application/json; charset=UTF-8"}
      })

      if(res.ok){
        message.success("Hesap başarıyla oluşturuldu!")
        setLoading(false);
        navigate("/login");
      }else{
        message.error("Bir şeyler yanlış gitti!")
      }
    } catch (error) {
      
    }
  }

  return (
    <div className="h-screen">
      <div className="flex justify-between h-full">
        <div className="xl:px-20 px-10 sm:px-10 w-full flex flex-col h-full justify-center relative">
          <h1 className="text-center text-5xl font-bold mb-2">LOGO</h1>
          <Form layout="vertical" onFinish={onFinish}>
            <Form.Item
              label="Kullanıcı Adı"
              name={"username"}
              rules={[
                { required: true, message: "Kullanıcı adı boş bırakılamaz!" },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Email"
              name={"email"}
              rules={[{ required: true, message: "Email boş bırakılamaz!" }]}
            >
              <Input type="email" />
            </Form.Item>
            <Form.Item
              label="Şifre"
              name={"password"}
              rules={[{ required: true, message: "Parola boş bırakılamaz!" }]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              label="Şifre Tekrar"
              dependencies={["password"]}
              name={"passwordagain"}
              rules={[
                { required: true, message: "Parola tekrar boş bırakılamaz!" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "Girdiğiniz parolalar eşleşmiyor!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Button htmlType="submit" type="primary" className="w-full" loading={loading}>
              Kaydol
            </Button>
          </Form>
          <div className="pt-auto text-center absolute bottom-10 left-0 w-full">
            <p>
              Bir hesabınız mı var?{" "}
              <NavLink to="/login" className="text-blue-500">
                Giriş yap
              </NavLink>
            </p>
          </div>
        </div>
        <div className="xl:w-4/6 lg:w-3/5 md:w-1/2 hidden bg-[#6c63ff] h-full sm:flex sm:w-1/2 items-center">
          <div className="w-full">
            <Carousel autoplay className="text-white">
              <AuthCarousel
                title="Responsive"
                desc="Tüm Cihaz Boyutlarıyla Uyumluluk"
                img="responsive.svg"
              />
              <AuthCarousel
                title="İstatistikler"
                desc="Geniş Tutulan İstatistikler"
                img="statistic.svg"
              />
              <AuthCarousel
                title="Müşteri Memnuniyeti"
                desc="Deneyim Sonunda Üründen Memnun Müşteriler"
                img="customer.svg"
              />
              <AuthCarousel
                title="Yönetim Paneli"
                desc="Tek Yerden Yönetim"
                img="admin.svg"
              />
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
