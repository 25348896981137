import React from "react";

const AuthCarousel = (props) => {
    const { title, desc, img } = props;
  return (
    <div className="!flex flex-col items-center justify-center !h-full mb-10">
      <img className="w-[600px] h-[500px]" src={`/img/${img}`} alt="" />
      <h3 className="text-4xl font-bold">{title}</h3>
      <p className="mt-5 text-2xl">{desc}</p>
    </div>
  );
};

export default AuthCarousel;
