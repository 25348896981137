import React from "react";
import { Modal, Form, Input, Button, message, Select } from "antd";
const Add = (props) => {
  const { isModalOpen, setisModalOpen, setProducts, products, categories } = props;
  const [form] = Form.useForm();
  const { Option } = Select;
  const onFinish = async (values) => {
    try {
      const res = await fetch(process.env.REACT_APP_SERVER_URL + "/api/products/add", {
        method: "POST",
        body: JSON.stringify(values),
        headers: { "Content-type": "application/json; charset=UTF-8" },
      });
      const data = await res.json();
      message.success("Ürün başarıyla eklendi.");
      form.resetFields();
      setProducts([...products, data]);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Modal
        title="Kategori Ekle"
        open={isModalOpen}
        onCancel={() => setisModalOpen(false)}
        footer={false}
      >
        <Form layout="vertical" onFinish={onFinish} form={form}>
          <Form.Item
            name="title"
            label="Ürün adı"
            rules={[
              { required: true, message: "Ürün adı alanı boş bırakılamaz!" },
            ]}
          >
            <Input placeholder="Ürün adı giriniz..." />
          </Form.Item>
          <Form.Item
            name="img"
            label="Ürün görseli"
            rules={[
              {
                required: true,
                message: "Ürün görseli alanı boş bırakılamaz!",
              },
            ]}
          >
            <Input placeholder="Ürün görsel linki giriniz..." />
          </Form.Item>
          <Form.Item
            name="price"
            label="Ürün fiyatı"
            rules={[
              { required: true, message: "Ürün fiyatı alanı boş bırakılamaz!" },
            ]}
          >
            <Input type="number" placeholder="Ürün fiyatı giriniz..." />
          </Form.Item>
          <Form.Item
            name="category"
            label="Ürün kategorisi"
            rules={[
              {
                required: true,
                message: "Ürün kategori alanı boş bırakılamaz!",
              },
            ]}
          >
            <Select showSearch placeholder="Kategori seçiniz...">
                {
                    categories.map(categorie => {
                        return(
                            <Option key={categorie._id} value={categorie.title}>{categorie.title}</Option>
                        )
                    })
                }
            </Select>
          </Form.Item>
          <Form.Item className="flex justify-end mb-0">
            <Button type="primary" htmlType="submit">
              Oluştur
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Add;
