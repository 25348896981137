import React from 'react'
import { Button } from 'antd'
import { ClearOutlined } from '@ant-design/icons'
import { useSelector, useDispatch  } from 'react-redux'
import { deleteAll } from '../../redux/cartSlice'
import CartItem from './CartItem'
const CartTotals = () => {
    const cart = useSelector((state) => state.cart)
    const dispatch = useDispatch();
    const deleteHandler = () => {
        dispatch(deleteAll());
    }
  return (
    <div className='cart h-full flex flex-col max-h-[calc(100vh_-_90px)]'>
        <h2 className='bg-blue-600 text-center py-4 text-white font-bold tracking-wide'>Sepetteki Ürünler</h2>
        <ul className='cart-items flex flex-col gap-y-2 px-2 overflow-y-auto py-2'>
            {
                cart.cartItems.map((cartitem, index) => {
                    return(
                        <CartItem key={index} cartitem={cartitem}/>
                    )
                }).reverse()
            }
        </ul>
        <div className="cart-totals mt-auto">
            <div className='border-t border-b'>
                <div className='flex justify-between p-2'>
                    <b>Ara Toplam</b>
                    <span>{cart.total.toFixed(2)}₺</span>
                </div>
                <div className='flex justify-between p-2'>
                    <b>KDV %8</b>
                    <span className='text-red-600'>+{((cart.total/100)*8).toFixed(2)}₺</span>
                </div>
            </div>
            <div className='border-b mt-4'>
                <div className='flex justify-between p-2'>
                    <b className='text-xl text-green-500'>Genel Toplam</b>
                    <span className='text-2xl'>{(cart.total + (cart.total/100)*8).toFixed(2)}</span>
                </div>
            </div>
            <div className='py-4 px-2'>
                <Button type='primary' size='large' className='w-full'>
                    Sipariş Oluştur
                </Button>
                <Button onClick={deleteHandler} type='primary' icon={<ClearOutlined />} size='large' className='w-full mt-2 flex items-center justify-center' danger>
                    Temizle
                </Button>
            </div>
        </div>
    </div>
  )
}

export default CartTotals