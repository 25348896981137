import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Form, Input, Button, Carousel, Checkbox, message } from "antd";
import AuthCarousel from "../../components/auth/AuthCarousel";
const Login = ({setUser}) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const onFinish = async (values) => {
    setLoading(true);
    try {
      const res = await fetch(process.env.REACT_APP_SERVER_URL +"/api/auth/login", {
        body: JSON.stringify(values),
        method: "POST",
        headers: {"Content-type" : "application/json; charset=UTF-8"}
      })

      const user = await res.json();
      console.log(user);

      if(res.ok){
        message.success("Giriş işlemi başarılı!")
        let c = {username: user.username, email: user.email}
        localStorage.setItem("posUser", JSON.stringify(c));
        setUser(JSON.stringify(c));
        setLoading(false);
        navigate("/");
      }else if(res.status === 404){
        message.error("Kullanıcı bulunamadı!")
        setLoading(false)
      }
      else if(res.status === 403){
        message.error("Hatalı parola!")
        setLoading(false)
      }
    } catch (error) {
      
    }
  }
  return (
    <div className="h-screen">
      <div className="flex justify-between h-full">
        <div className="xl:px-20 px-10 sm:px-10 w-full flex flex-col h-full justify-center relative">
          <h1 className="text-center text-5xl font-bold mb-2">LOGO</h1>
          <Form layout="vertical" onFinish={onFinish} initialValues={{
            remember: false
          }}>
            <Form.Item
              label="Email"
              name={"email"}
              rules={[{ required: true, message: "Email boş bırakılamaz!" }]}
            >
              <Input type="email" />
            </Form.Item>
            <Form.Item
              label="Şifre"
              name={"password"}
              rules={[{ required: true, message: "Parola boş bırakılamaz!" }]}
            >
              <Input.Password />
            </Form.Item>
            <div className="flex justify-between">
              <Form.Item name={"remember"}>
                <Checkbox>Beni hatırla</Checkbox>
              </Form.Item>
              <Form.Item>
                <NavLink to="/forgotpassword">Parolanı mı unuttun?</NavLink>
              </Form.Item>
            </div>

            <Button htmlType="submit" type="primary" className="w-full" loading={loading}>
              Giriş Yap
            </Button>
          </Form>
          <div className="pt-auto text-center absolute bottom-10 left-0 w-full">
            <p>
              Bir hesabınız yok mu?{" "}
              <NavLink to="/register" className="text-blue-500">
                Kayıt ol
              </NavLink>
            </p>
          </div>
        </div>
        <div className="xl:w-4/6 lg:w-3/5 md:w-1/2 hidden bg-[#6c63ff] h-full sm:flex sm:w-1/2 items-center">
          <div className="w-full">
            <Carousel autoplay className="text-white">
              <AuthCarousel
                title="Responsive"
                desc="Tüm Cihaz Boyutlarıyla Uyumluluk"
                img="responsive.svg"
              />
              <AuthCarousel
                title="İstatistikler"
                desc="Geniş Tutulan İstatistikler"
                img="statistic.svg"
              />
              <AuthCarousel
                title="Müşteri Memnuniyeti"
                desc="Deneyim Sonunda Üründen Memnun Müşteriler"
                img="customer.svg"
              />
              <AuthCarousel
                title="Yönetim Paneli"
                desc="Tek Yerden Yönetim"
                img="admin.svg"
              />
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
