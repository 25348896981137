import { Modal, Form, Table, Input, Button, message } from 'antd'
import React, { useState } from 'react'

const Edit = (props) => {
    const {isEditModalOpen, setisEditModalOpen, categories, setCategories} = props;
    const [editingRow, setEditingRow] = useState("");

    const update = (values) => {
        try {
            fetch(process.env.REACT_APP_SERVER_URL + "/api/categories/update", {
                method: "PUT",
                body: JSON.stringify({...values, id: editingRow}),
                headers: {"Content-type": "application/json; charset=UTF-8"}
            });
            setCategories(categories.map(x => {
                if(x._id === editingRow){
                    return { ...x, title: values.title }
                }
                return x
            }))
            message.success("Kategori başarıyla güncellendi.")
        } catch (error) {
            console.log(error);
        }
    }

    const delCategory = (id) => {
        if(window.confirm("Emin misiniz?")){
            try {
                fetch(process.env.REACT_APP_SERVER_URL + "/api/categories/delete", {
                    method: "DELETE",
                    body: JSON.stringify({id}),
                    headers: {"Content-type": "application/json; charset=UTF-8"}
                });
                setCategories(categories.filter(x => x._id !== id));
                message.success("Kategori başarıyla silindi.")
            } catch (error) {
                console.log(error)
            }
        } 
    }

    let a = 1;
    const columns = [
        {
            title: "Kategori Başlığı",
            key: a++,
            dataIndex: "title",
            render: (_,record) => {
                if (record._id === editingRow){
                    return (
                        <Form.Item name="title" className='mb-0'>
                            <Input defaultValue={record.title} />
                        </Form.Item>
                    )
                }else {
                    return(
                        <p>{record.title}</p>
                    )
                    
                }
            }
        },
        {
            title:"Eylem",
            dataIndex: "action",
            render: (_, record) => {
                return (
                    <div>
                        <Button className='pl-0' type='link' onClick={() => setEditingRow(record._id)}>Düzenle</Button>
                        <Button type='text' htmlType='submit'>Kaydet</Button>
                        <Button type='text' onClick={() => delCategory(record._id)} danger>Sil</Button>
                    </div>
                    
                )
            }
        }
    ]

  return (
    <Modal title="Kategori İşlemleri" open={isEditModalOpen} onCancel={() => setisEditModalOpen(false)} footer={false}>
        <Form onFinish={update}>
            <Table bordered dataSource={categories} columns={columns} rowKey={"_id"}>

            </Table>
        </Form>
    </Modal>
  )
}

export default Edit