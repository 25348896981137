import React from "react";
import { Modal, Form, Input, Button, message } from "antd";
const AddCategory = (props) => {
  const { isModalOpen, setisModalOpen, setCategories, categories } = props;
  const [form] = Form.useForm();
  const onFinish = async (values) => {
    try {
      const res = await fetch(process.env.REACT_APP_SERVER_URL + "/api/categories/add", {
        method: "POST",
        body: JSON.stringify(values),
        headers: {"Content-type": "application/json; charset=UTF-8"}
      });
      const data = await res.json();
      message.success("Kategori başarıyla eklendi.")
      form.resetFields();
      setCategories([...categories, data])
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div>
      <Modal
        title="Kategori Ekle"
        open={isModalOpen}
        onCancel={() => setisModalOpen(false)}
        footer={false}
      >
        <Form layout="vertical" onFinish={onFinish} form={form}>
          <Form.Item name="title" label="Kategori" rules={[{ required: true, message:"Kategori alanı boş bırakılamaz!" }]}>
            <Input  placeholder="Kategori giriniz..."/>
          </Form.Item>
          <Form.Item className="flex justify-end mb-0">
            <Button type="primary" htmlType="submit">Oluştur</Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AddCategory;
