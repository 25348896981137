import { Form, Table, Input, Button, message, Space, Modal, Select } from 'antd'
import { SearchOutlined } from '@ant-design/icons';
import React, { useState, useRef } from 'react'
import Highlighter from 'react-highlight-words';

const Edit = (props) => {
    const { form } = Form.useForm();
    const { Option } = Select;
    const { categories, products, setProducts} = props;
    const [editingRow, setEditingRow] = useState("");
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [isModalOpen, setisModalOpen] = useState(false);
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
      clearFilters();
      setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
        <div
          style={{
            padding: 8,
          }}
          onKeyDown={(e) => e.stopPropagation()}
        >
          <Input
            ref={searchInput}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{
              marginBottom: 8,
              display: 'block',
            }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{
                width: 90,
              }}
            >
              Search
            </Button>
            <Button
              onClick={() => clearFilters && handleReset(clearFilters)}
              size="small"
              style={{
                width: 90,
              }}
            >
              Reset
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                confirm({
                  closeDropdown: false,
                });
                setSearchText(selectedKeys[0]);
                setSearchedColumn(dataIndex);
              }}
            >
              Filter
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                close();
              }}
            >
              close
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined
          style={{
            color: filtered ? '#1677ff' : undefined,
          }}
        />
      ),
      onFilter: (value, record) =>
        record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current?.select(), 100);
        }
      },
      render: (text) =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{
              backgroundColor: '#ffc069',
              padding: 0,
            }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          text
        ),
    });

    const onFinish = (values) => {
        try {
            fetch(process.env.REACT_APP_SERVER_URL + "/api/products/update", {
                method: "PUT",
                body: JSON.stringify({...values, id: editingRow._id}),
                headers: {"Content-type": "application/json; charset=UTF-8"}
            });
            setProducts(products.map(x => {
              if(x._id === editingRow._id){
                  return {...x, title: values.title, img: values.img, price: values.price, category: values.category }
              }
              return x
          }))
            message.success("Ürün başarıyla güncellendi.")
        } catch (error) {
            console.log(error);
        }
    }

    const delProduct = (id) => {
        if(window.confirm("Emin misiniz?")){
            try {
                fetch(process.env.REACT_APP_SERVER_URL +"/api/products/delete", {
                    method: "DELETE",
                    body: JSON.stringify({id}),
                    headers: {"Content-type": "application/json; charset=UTF-8"}
                });
                setProducts(products.filter(x => x._id !== id));
                message.success("Ürün başarıyla silindi.")
            } catch (error) {
                console.log(error)
            }
        } 
    }

    const columns = [
        {
            title: "Ürün Görseli",
            width: "4%",
            dataIndex: "img",
            render: (_, record) => {
                return (
                    <img src={record.img} className="w-full h-20 object-cover" alt={record.title} />
                )
            }
        },
        {
            title: "Ürün Adı",
            width: "8%",
            dataIndex: "title",
            ...getColumnSearchProps('title'),
            render: (_,record) => {
                if (record._id === editingRow){
                    return (
                        <Form.Item name="title" className='mb-0'>
                            <Input defaultValue={record.title} />
                        </Form.Item>
                    )
                }else {
                    return(
                        <p>{record.title}</p>
                    )
                    
                }
            }
        },
        {
            title: "Ürün Fiyatı",
            width: "8%",
            dataIndex: "price",
            render: (_, record) => {
                return (
                    <span>{record.price}₺</span>
                )
            }
        },
        {
            title: "Kategori",
            width: "8%",
            dataIndex: "category"
        },
        {
            title:"Eylem",
            width: "8%",
            dataIndex: "action",
            render: (_, record) => {
              console.log(record);
                return (
                    <div>
                        <Button className='pl-0' type='link' onClick={() => {setisModalOpen(true); setEditingRow(record)}}>Düzenle</Button>
                        <Button type='text' onClick={() => delProduct(record._id)} danger>Sil</Button>
                    </div>
                    
                )
            }
        }
    ]

  return (
    <div>
      <Table bordered dataSource={products} columns={columns} rowKey={"_id"} scroll={{x: 1000, y:600}} />
      <Modal
        title="Ürün Düzenleme"
        open={isModalOpen}
        onCancel={() => setisModalOpen(false)}
        footer={false}
      >
        <Form layout="vertical" onFinish={onFinish} form={form} initialValues={editingRow}>
          <Form.Item
            name="title"
            label="Ürün adı"
            rules={[
              { required: true, message: "Ürün adı alanı boş bırakılamaz!" },
            ]}
          >
            <Input placeholder="Ürün adı giriniz..." />
          </Form.Item>
          <Form.Item
            name="img"
            label="Ürün görseli"
            rules={[
              {
                required: true,
                message: "Ürün görseli alanı boş bırakılamaz!",
              },
            ]}
          >
            <Input  placeholder="Ürün görsel linki giriniz..." />
          </Form.Item>
          <Form.Item
            name="price"
            label="Ürün fiyatı"
            rules={[
              { required: true, message: "Ürün fiyatı alanı boş bırakılamaz!" },
            ]}
          >
            <Input  type="number" placeholder="Ürün fiyatı giriniz..." />
          </Form.Item>
          <Form.Item
            name="category"
            label="Ürün kategorisi"
            rules={[
              {
                required: true,
                message: "Ürün kategori alanı boş bırakılamaz!",
              },
            ]}
          >
            <Select showSearch placeholder="Kategori seçiniz...">
                {
                    categories.map(categorie => {
                      return(
                        <Option key={categorie._id} value={categorie.title}>{categorie.title}</Option>
                      ) 
                    })
                }
            </Select>
          </Form.Item>
          <Form.Item className="flex justify-end mb-0">
            <Button type="primary" htmlType="submit">
              Kaydet
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
    
       
  )
}

export default Edit