import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Form, Input, Select, Card, Button } from "antd";
import { deleteAll } from "../../redux/cartSlice";
import PrintBill from "../bills/PrintBill";
const CreateBill = (props) => {
  const [bill, setBill] = useState({})
  const [billModalOpen, setBillModalOpen] = useState(false);
  const dispatch = useDispatch()
  const cart = useSelector((state) => state.cart);
  const { isModalOpen, setisModalOpen } = props;


  const onFinish = (values) => {
    try {
      const addBill = async () => {
        const response = await fetch(process.env.REACT_APP_SERVER_URL + "/api/bills/add", {
          method: "POST",
          body: JSON.stringify({...values, cartItems: cart.cartItems, tax: 8, subTotal: cart.total, totalAmount: ((cart.total * 0.08) + cart.total).toFixed(2) }),
          headers: {"Content-type": "application/json; charset=UTF-8"}
        })

        const json = await response.json();
        if(response.ok){
          setBill(json);
          setBillModalOpen(true);
          setisModalOpen(false);
          dispatch(deleteAll())
        }
        console.log(json);
      }

      addBill();
    } catch (error) {
      
    }
  }

  return (
    <div>
      <Modal
        title="Fatura Oluştur"
        open={isModalOpen}
        onCancel={() => setisModalOpen(false)}
        footer={false}
      >
        <Form layout="vertical" onFinish={onFinish}>
          <Form.Item name="customerName" label="Müşteri Adı" rules={[{ required: true, message:"Müşteri adı alanı boş bırakılamaz!" }]}>
            <Input  placeholder="Müşteri Adı giriniz..."/>
          </Form.Item>
          <Form.Item name="customerPhone" label="Telefon Numarası" rules={[{ required: true, message:"Telefon numarası alanı boş bırakılamaz!" }]}>
            <Input  placeholder="Telefon numarası giriniz..."/>
          </Form.Item>
          <Form.Item name={"paymentMethod"} label="Ödeme Yöntemi" rules={[{ required: true, message:"Ödeme yöntemi alanı boş bırakılamaz!" }]}>
            <Select placeholder="Ödeme yöntemi seçiniz...">
              <Select.Option value="Nakit">Nakit</Select.Option>
              <Select.Option value="Kredi Kartı">Kredi Kartı</Select.Option>
            </Select>
          </Form.Item>
          <Card>
            <div className="flex justify-between">
              <span>Ara Toplam</span>
              <span>{cart.total.toFixed(2)}₺</span>
            </div>
            <div className="flex justify-between my-2">
              <span>KDV Toplam %8</span>
              <span className="text-red-600">+{(cart.total * 0.08).toFixed(2)}₺</span>
            </div>
            <div className="flex justify-between">
              <b>Toplam</b>
              <b>{(cart.total + (cart.total * 0.08)).toFixed(2)}₺</b>
            </div>
            <div className="flex justify-end">
              <Button
                onClick={() => setisModalOpen(true)}
                type="primary"
                className="mt-2"
                htmlType="submit"
              >
                Sipariş Oluştur
              </Button>
            </div>
          </Card>
        </Form>
      </Modal>
      <PrintBill isModalOpen={billModalOpen} setisModalOpen={setBillModalOpen} bill={bill}/>
    </div>
  );
};

export default CreateBill;
