import React from "react";
import { addProduct } from "../../redux/cartSlice";
import { useDispatch } from "react-redux";
import { message } from "antd";
const ProductItem = ({product}) => {

  
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(addProduct({...product, quantity: 1}))
    message.success("Ürün başarıyla eklendi!")
  }
  return (
    <div
      onClick={handleClick}
      key={product._id}
      className="product-item border hover:shadow-md cursor-pointer transition-all select-none"
    >
      <div className="product-img">
        <img
          src={product.img}
          alt=""
          className="h-28 object-cover w-full border-b"
        />
      </div>
      <div className="product-info flex flex-col p-3">
        <span className="font-bold">{product.title}</span>
        <span>{product.price}₺</span>
      </div>
    </div>
  );
};

export default ProductItem;
