import React from "react";
import { Button } from "antd";
import { useDispatch } from "react-redux";
import { minusProduct, addProduct, deleteProduct } from "../../redux/cartSlice";
import { PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons'
const CartItem = ({cartitem}) => {
    const dispatch = useDispatch();
    const handleMinus = () => {
        dispatch(minusProduct(cartitem));
    }

    const handleAdd = () => {
        dispatch(addProduct(cartitem))
    }

    const handleDelete = () => {
        dispatch(deleteProduct(cartitem))
    }

  return (
    <li className="cart-item flex justify-between border-b">
      <div onClick={handleDelete} className="flex items-center cursor-pointer">
        <img
          className="w-16 h-16 object-cover"
          src={cartitem.img}
          alt=""
        />
        <div className="flex flex-col ml-2">
          <b>{cartitem.title}</b>
          <span>{cartitem.price}₺ x {cartitem.quantity}</span>
        </div>
      </div>
      <div className="flex items-center gap-x-1 pr-2">
        <Button
            onClick={handleAdd}
          type="primary"
          icon={<PlusCircleOutlined />}
          size="small"
          className="w-full flex items-center justify-center !rounded-full"
        />
        <span>{cartitem.quantity}</span>
        <Button
            onClick={handleMinus}
          type="primary"
          icon={<MinusCircleOutlined />}
          size="small"
          className="w-full flex items-center justify-center !rounded-full"
        />
      </div>
    </li>
  );
};

export default CartItem;
