import React, { useEffect, useState } from "react";
import StatsCard from "../components/stats/StatsCard";
import { Area, Pie } from "@ant-design/plots";
import { Spin } from "antd";
const StatsPage = () => {
  const user = JSON.parse(localStorage.getItem("posUser"));
  const [data, setData] = useState();
  const [products, setProducts] = useState();
  let total = 0;
  let customers = [];

  data?.forEach((item) => {
    total += item.totalAmount;
    if (!customers.includes(item.customerName)) {
      customers.push(item.customerName);
    }
  });

  useEffect(() => {
    asyncFetch();
    getProducts();
  }, []);

  const getProducts = async () => {
    try {
      const res = await fetch(process.env.REACT_APP_SERVER_URL +"/api/products/get-all");
      const json = await res.json();
      setProducts(json);
    } catch (error) {
      console.log(error);
    }
  };

  const asyncFetch = () => {
    fetch(process.env.REACT_APP_SERVER_URL +"/api/bills/get-all")
      .then((response) => response.json())
      .then((json) => setData(json))
      .catch((error) => {
        console.log("fetch data failed", error);
      });
  };

  const config = {
    data,
    xField: "createdAt",
    yField: "totalAmount",
    xAxis: {
      range: [0, 1],
    },
  };

  const config2 = {
    appendPadding: 10,
    data: data,
    angleField: "subTotal",
    colorField: "customerName",
    radius: 1,
    innerRadius: 0.6,
    label: {
      type: "inner",
      offset: "-50%",
      content: "{value}",
      style: {
        textAlign: "center",
        fontSize: 14,
      },
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: "pre-wrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        content: "Test",
      },
    },
  };

  return (
    <>
      {products && data ? (
        <div className="px-6 md:pb-0 pb-20">
          <h1 className="text-4xl font-bold text-center mb-4">İstatistikler</h1>
          <div className="stats-section">
            <h2 className="text-lg">
              Hoş geldin
              <span className="text-green-700 font-bold text-xl">
                {" "}
                {user.username}
              </span>
              .
            </h2>
            <div className="stats-cards grid gap-4 md:gap-10 grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 my-10">
              <StatsCard title="Toplam Müşteri" value={customers.length} />
              <StatsCard title="Toplam Kazanç" value={`${total}₺`} />
              <StatsCard title="Toplam Satış" value={data.length} />
              <StatsCard title="Toplam Ürün" value={products.length} />
            </div>
            <div className="stats-charts grid grid-cols-1 md:grid-cols-2">
              <Area {...config} />
              <Pie {...config2} />
            </div>
          </div>
        </div>
      ) : (
        <Spin size="large" className="absolute top-1/3 left-1/2" />
      )}
    </>
  );
};

export default StatsPage;
