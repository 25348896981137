import React, { useRef } from "react";
import { Modal, Button } from "antd";
import { useReactToPrint } from "react-to-print";

const PrintBill = (props) => {
  const componentRef = useRef();
  const { isModalOpen, setisModalOpen, bill } = props;
  console.log(bill);
  const date = new Date(bill?.createdAt).toLocaleDateString("tr-TR");

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })

  return (
    <div>
      <Modal
        title="Fatura Yazdır"
        open={isModalOpen}
        onCancel={() => setisModalOpen(false)}
        footer={false}
        width={800}
      >
        <section className="py-20 bg-black" ref={componentRef}>
          <div className="max-w-5xl mx-auto bg-white px-6">
            <article className="overflow-hidden">
              <div className="logo my-6">
                <h2 className="text-4xl font-bold text-slate-700">LOGO</h2>
              </div>
              <div className="bill-details">
                <div className="grid sm:grid-cols-4 grid-cols-3 gap-12">
                  <div className="sm:text-md text-slate-500">
                    <p className="font-bold text-slate-700">Fatura Detayı:</p>
                    <p>{bill?.customerName}</p>
                    <p>Fake Street 123</p>
                    <p>San Javier</p>
                    <p>CA 1234</p>
                  </div>
                  <div className="text-md text-slate-500">
                    <p className="font-bold text-slate-700">Fatura</p>
                    <p>The Boring Company</p>
                    <p>Tesla Street 007</p>
                    <p>Frisco</p>
                    <p>CA 0000</p>
                  </div>
                  <div className="text-md text-slate-500">
                    <div>
                      <p className="font-bold text-slate-700">
                        Fatura Numarası
                      </p>
                      <p>00041</p>
                    </div>
                    <div>
                      <p className="font-bold text-slate-700 mt-2">
                        Veriliş Tarihi
                      </p>
                      <p>{date}</p>
                    </div>
                  </div>
                  <div className="text-md text-slate-500 sm:block hidden">
                    <div>
                      <p className="font-bold text-slate-700">Şartlar</p>
                      <p>0 Days</p>
                    </div>
                    <div>
                      <p className="font-bold text-slate-700 mt-2">Due</p>
                      <p>00.00.00</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bill-table-area mt-8">
                <table className="min-w-full divide-y divide-slate-500 overflow-hidden">
                  <thead>
                    <tr className="border-b border-slate-200">
                      <th
                        scope="col"
                        className="py-3.5 text-left text-sm font-normal text-slate-700 md:pl-0 sm:table-cell hidden"
                      >
                        Görsel
                      </th>
                      <th
                        scope="col"
                        className="-sm:w-full py-3.5 text-left text-sm font-normal text-slate-700 md:pl-0"
                      >
                        Başlık
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 text-center text-sm font-normal text-slate-700 md:pl-0 sm:table-cell hidden"
                      >
                        Fiyat
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 text-center text-sm font-normal text-slate-700 md:pl-0 sm:table-cell hidden"
                      >
                        Adet
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 text-end sm:text-end text-sm font-normal text-slate-700"
                      >
                        Toplam
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      bill?.cartItems?.map(item => {
                        return(
                          <tr className="border-b border-slate-200">
                            <td className="py-4 sm:table-cell hidden">
                              <img
                                className="w-12 h-12 object-cover"
                                src={item.img}
                                alt=""
                              />
                            </td>
                            <td className="py-4">
                              <div className="flex flex-col">
                                <span className="font-medium">{item.title}</span>
                                <span className="text-xs sm:hidden">{item.quantity} x {item.price}₺</span>
                              </div>
                            </td>
                            <td className="py-4 text-center hidden sm:table-cell">
                              <span>{item.price}₺</span>
                            </td>
                            <td className="py-4 text-center hidden sm:table-cell">
                              <span>{item.quantity}</span>
                            </td>
                            <td className="py-4 text-right">
                              <span>{item.price * item.quantity}₺</span>
                            </td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                  <tfoot className="mt-4">
                    <tr>
                      <th
                        className="text-right pt-4 font-normal sm:table-cell hidden text-slate-700"
                        colSpan={4}
                        scope="row"
                      >
                        Ara Toplam
                      </th>
                      <th
                        className="text-left pt-4 font-normal text-slate-700 sm:hidden"
                        scope="row"
                      >
                        <p>Ara Toplam</p>
                      </th>
                      <th
                        className="text-right pt-4 font-normal text-slate-700"
                        scope="row"
                      >
                        {bill?.subTotal}₺
                      </th>
                    </tr>
                    <tr>
                      <th
                        className="text-right pt-4 font-normal sm:table-cell hidden text-slate-700"
                        colSpan={4}
                        scope="row"
                      >
                        KDV %8
                      </th>
                      <th
                        className="text-left pt-4 font-normal text-slate-700 sm:hidden"
                        scope="row"
                      >
                        KDV %8
                      </th>
                      <th
                        className="text-right pt-4 font-normal text-red-500"
                        scope="row"
                      >
                        {bill?.subTotal * 0.08}₺
                      </th>
                    </tr>
                    <tr>
                      <th
                        className="text-right pt-4 font-normal sm:table-cell hidden text-slate-700"
                        colSpan={4}
                        scope="row"
                      >
                        Toplam
                      </th>
                      <th
                        className="text-left pt-4 font-normal text-slate-700 sm:hidden"
                        scope="row"
                      >
                        Toplam
                      </th>
                      <th
                        className="text-right pt-4 font-normal text-slate-700"
                        scope="row"
                      >
                        {bill?.totalAmount?.toFixed(2)}₺
                      </th>
                    </tr>
                  </tfoot>
                </table>
                <div className="py-9">
                  <div className="border-t pt-9 border-slate-200">
                    <p className="text-sm font-light text-slate-700">
                      Ödeme koşulları 14 gündür. Paketlenmemiş Borçların Geç
                      Ödenmesi Yasası 0000'e göre, serbest çalışanların bu
                      süreden sonra borçların ödenmemesi durumunda 00.00 gecikme
                      ücreti talep etme hakkına sahip olduklarını ve bu noktada
                      bu ücrete ek olarak yeni bir fatura sunulacağını lütfen
                      unutmayın. Revize faturanın 14 gün içinde ödenmemesi
                      durumunda, vadesi geçmiş hesaba ek faiz ve %8 yasal oran
                      artı %0,5 Bank of England tabanı olmak üzere toplam %8,5
                      uygulanacaktır. Taraflar Kanun hükümleri dışında sözleşme
                      yapamazlar.
                    </p>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </section>
        <div className="flex justify-end mt-4">
            <Button onClick={handlePrint} type="primary" size="large">Yazdır</Button>
        </div>
      </Modal>
    </div>
  );
};

export default PrintBill;
