import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
    name: "cart",
    initialState: {
        cartItems: localStorage.getItem("cart") ? JSON.parse(localStorage.getItem("cart")).cartItems : [],
        total: localStorage.getItem("cart") ? JSON.parse(localStorage.getItem("cart")).total : 0
    },
    reducers: {
        addProduct: (state, action) => {
            state.total += action.payload.price;
            const findCartItem = state.cartItems.find(item => item._id === action.payload._id);
            if(findCartItem){
                findCartItem.quantity +=1;
            }else{
                state.cartItems.push(action.payload)
            }
        },
        minusProduct: (state, action) => {
            state.total -= action.payload.price;
            const findCartItem = state.cartItems.find(item => item._id === action.payload._id);
           
            if(findCartItem.quantity === 1){
                state.cartItems = state.cartItems.filter(item => item._id !== findCartItem._id);
            }else{
                findCartItem.quantity -= 1;
            }
        },
        deleteProduct: (state, action) => {
            state.total -= action.payload.price * action.payload.quantity;
            state.cartItems = state.cartItems.filter(item => item._id !== action.payload._id);
        },
        deleteAll: (state, action) => {
            state.cartItems = [];
            state.total = 0
        }
    }
})



export const {addProduct, deleteAll, minusProduct, deleteProduct} = cartSlice.actions
export default cartSlice.reducer