import React, { useEffect, useState } from "react";
import { Table, Button, Spin } from "antd";
import PrintBill from "../components/bills/PrintBill";
const BillPage = () => {
  const [currentBill, setCurrentBill] = useState({})
  const [bills, setBills] = useState()
  useEffect(() => {
    const getBills = async () => {
      const res = await fetch(process.env.REACT_APP_SERVER_URL + "/api/bills/get-all");
      console.log(res);
      const json = await res.json();
      if(res.ok){
        setBills(json)
      }
    }

    getBills()
  }, [])

  

  const columns = [
    {
      title: "Müşteri Adı",
      dataIndex: "customerName",
      key: "customerName",
    },
    {
      title: "Telefon Numarası",
      dataIndex: "customerPhone",
      key: "customerPhone",
    },
    {
      title: "Oluşturma Tarihi",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => {
        const date = new Date(text).toLocaleDateString("tr-TR");
        return(
          <span>{date}</span>
        )
      }
    },
    {
      title: "Ödeme Yöntemi",
      dataIndex: "paymentMethod",
      key: "paymentMethod",
    },
    {
      title: "Toplam Fiyat",
      dataIndex: "totalAmount",
      key: "totalAmount",
      render: (text) => {
        return(
          <span>{text}₺</span>
        )
      }
    },
    {
      title: "Eylem",
      render: (_,record) => {
        return(
          <Button type="link" onClick={() => {setisModalOpen(true); setCurrentBill(record)}}>Yazdır</Button>
        )
      }
    },
  ];

  const [isModalOpen, setisModalOpen] = useState(false);

  return (
    <>
    {
      bills ? (
        <div className="px-6">
      <h1 className="text-4xl font-bold text-center mb-4">Faturalar</h1>
      <Table
        dataSource={bills}
        columns={columns}
        bordered
        pagination={false}
        scroll={{
          x: 1000,
          y: 300
        }}
        rowKey={"_id"}
      />
      <PrintBill bill={currentBill} isModalOpen={isModalOpen} setisModalOpen={setisModalOpen} />
    </div>
      ) : <Spin size="large" className="absolute top-1/3 left-1/2"/>
    }
    </> 
  );
};

export default BillPage;
