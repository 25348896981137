import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Input, Badge } from "antd";
import { searchProduct} from "../../redux/searchSlice";
import {
  SearchOutlined,
  HomeOutlined,
  ShoppingCartOutlined,
  CopyOutlined,
  UserOutlined,
  BarChartOutlined,
  LogoutOutlined,
} from "@ant-design/icons";

const Header = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const cart = useSelector((state) => state.cart);
  const { pathname, setUser } = props;

  const handleSearch = (event) => {
    dispatch(searchProduct({words: event.target.value}))
  }

  const logOut = () => {
    localStorage.removeItem("posUser");
    setUser(null)
    navigate("/login");
  }

  return (
    <div className={pathname === "/register" || pathname === "/login" ?  "hidden" : "border-b mb-6"}>
      <header className="py-4 px-6 flex gap-10 justify-between items-center">
        <div className="logo">
          <NavLink to="/">
            <h2 className="text-2xl font-bold md:text-4xl">LOGO</h2>
          </NavLink>
        </div>
        <div className="header-search flex-1 flex justify-center">
          <Input
            onClick={() => {pathname !== "/" && navigate("/")}}
            onChange={handleSearch}
            className="rounded-full max-w-[800px] "
            size="large"
            placeholder="Search"
            prefix={<SearchOutlined />}
          />
        </div>
        <div className="menu-links flex justify-between items-center gap-7 md:static fixed z-50 bottom-0 md:w-auto w-screen md:bg-transparent md:border-t-0 left-0 border-t bg-white md:px-0 px-2 py-1">
          <NavLink
            to="/"
            className="menu-link flex flex-col hover:text-[#40a9ff] transition-all"
          >
            <HomeOutlined className="md:text-2xl text-xl" />
            <span className="md:text-xs text-[10px]">Home</span>
          </NavLink>
          <NavLink to="cart" className={`menu-link hidden md:flex`}>
            <Badge
              offset={[0, 0]}
              count={cart?.cartItems.length}
              className={`menu-link flex flex-col hover:text-[#40a9ff] transition-all ${pathname === "/cart" && "active"}`}
            >
              <ShoppingCartOutlined className="md:text-2xl text-xl" />
              <span className="md:text-xs text-[10px]">Cart</span>
            </Badge>
          </NavLink>
          <NavLink
            to="/bills"
            className={`menu-link flex flex-col hover:text-[#40a9ff] transition-all ${pathname === "/bills" && "active"}`}
          >
            <CopyOutlined className="md:text-2xl text-xl" />
            <span className="md:text-xs text-[10px]">Bills</span>
          </NavLink>
          <NavLink
            to="/clients"
            className={`menu-link flex flex-col hover:text-[#40a9ff] transition-all ${pathname === "/clients" && "active"}`}
          >
            <UserOutlined className="md:text-2xl text-xl" />
            <span className="md:text-xs text-[10px]">Clients</span>
          </NavLink>
          <NavLink
            to="/stats"
            className={`menu-link flex flex-col hover:text-[#40a9ff] transition-all ${pathname === "/stats" && "active"}`}
          >
            <BarChartOutlined className="md:text-2xl text-xl" />
            <span className="md:text-xs text-[10px]">Stats</span>
          </NavLink>
          <NavLink
            onClick={logOut}
            className="flex flex-col hover:text-[#40a9ff] transition-all"
          >
            <LogoutOutlined className="md:text-2xl text-xl" />
            <span className="md:text-xs text-[10px]">Logout</span>
          </NavLink>
        </div>
        <NavLink to="/cart" className="menu-link md:hidden flex">
          <Badge
            offset={[0, 0]}
            count={cart?.cartItems.length}
            className="flex flex-col hover:text-[#40a9ff] transition-all"
          >
            <ShoppingCartOutlined className="md:text-2xl text-xl" />
            <span className="md:text-xs text-[10px]">Cart</span>
          </Badge>
        </NavLink>
      </header>
    </div>
  );
};

export default Header;
