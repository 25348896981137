import AppRouter from "./routes/AppRouter";
import { BrowserRouter as Router, HashRouter } from "react-router-dom";
function App() {
  return (
   <div>
      <HashRouter>
        <AppRouter />
      </HashRouter>
   </div>
    
  );
}

export default App;
